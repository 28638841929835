import { useState } from "react";
import './App.css';


function App() {

  const [ value, setValue ] = useState('');

  const getValue = e => setValue(e.target.value);
  const password = "Urodziny.exe";

  return (
    <div className="App">
      <form className="Form">
        <label htmlFor="secretPassword" className="Form-label">
          {value === password ? "Miłego oglądania :)" : "Wpisz tajne hasło"}
        </label>
        {value === password ?
          <a
            href="https://jakub.jarosiewicz.pl/Urodziny_Kuby.zip"
            className="Form-btn"
          >
            Pobierz
          </a>
        :
          <input
            type="text"
            id="secretPassword"
            name="secretPassword"
            onChange={getValue}
            className="Form-input"
            placeholder="Wpisz hasło"
          />
        }
      </form>
    </div>
  );
}

export default App;
